import React from 'react'
import Helmet from 'react-helmet'

import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../../components/Layout'
import FullPage from '../../components/FullPage'
import WorkTitleBlock from '../../components/WorkTitleBlock'

export const frontmatter = {
  title: 'Vitosoft Logo',
  type: 'Identity',
  client: 'Inforotor',
  path: '/works/identity/vitosoft-logo/',
  date: '2011-02-19',
  image: 'vitosoft-logo-demo',
  favorite: true,
}

// TODO: add rocket illustration here

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "vitosoft/i/vitosoft-logo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />
      <FullPage>
        <figure className="text-center">
          <Image
            fluid={query.file.childImageSharp.fluid}
            style={{
              maxWidth: 700,
              margin: 'auto',
            }}
            alt="Vitosoft logo"
          />
        </figure>
      </FullPage>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
