import React from 'react'
import PropTypes from 'prop-types'

import styles from './full.module.scss'

const FullPage = props => {
  const { children, align, className } = props

  const wrapperClassName = [
    styles.full,
    align === 'top' ? styles.top : null,
    align === 'bottom' ? styles.bottom : null,
    className,
  ].join(' ')

  return <section className={wrapperClassName}>{children}</section>
}

FullPage.propTypes = {
  children: PropTypes.any.isRequired,
  align: PropTypes.string,
  className: PropTypes.string,
}

export default FullPage
